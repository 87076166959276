import React, { Component } from "react";
import glamorous from "glamorous";
import { ColourPalette } from "../globals";

const Background = require("./../assets/intercontinental-melbourne-5361290223-4x3.jpeg");

const Wrapper = glamorous.div({
  height: "120vh",
  width: "100%",
  backgroundColor: "#293847",
  backgroundImage: "url(" + Background + ")",
  backgroundSize: "cover",
  paddingTop: "10vh",
});

const ContentWrapper = glamorous.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

class Home2 extends Component {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <div
            style={{
              backgroundColor: ColourPalette.Cream,
              width: "50vw",
              height: "110vh",
              padding: "",
            }}
          />
          <div
            style={{
              backgroundColor: ColourPalette.DarkGrey,
              width: "30vw",
              height: "110vh",
            }}
          />
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default Home2;
