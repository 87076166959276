import React, { Component } from "react";
import glamorous from "glamorous";
import { FontColour, FontSize, FontWeight, Transition } from "../../globals";

const Wrapper = glamorous.div({
  color: FontColour.Light,
  fontSize: FontSize.NavBar,
  fontWeight: FontWeight.DemiBold,
  transition: Transition.Short,
  borderBottom: "2px solid transparent",
  ":hover": {
    cursor: "pointer",
    borderBottom: "2px solid white"
  }
});
class NavBarItem extends Component {
  render() {
    return <Wrapper>{this.props.text}</Wrapper>;
  }
}

export default NavBarItem;
