export const ColourPalette = {
  TealLight: "#5ECCC5",
  Cream: "#FFF9F1",
  DarkGrey: "#4C4C4C",
};

export const FontColour = {
  Light: "#ffffff",
};

export const FontSize = {
  NavBar: "40px",
  SubHeader: "32px",
};

export const FontWeight = {
  DemiBold: 600,
};

export const Transition = {
  Normal: "0.4s",
  Short: "0.25s",
};
