import React, { Component } from "react";
import glamorous from "glamorous";
import NavBarItem from "./atoms/NavBarItem";
import { ColourPalette, Transition } from "../globals";

const BackgroundHeader = require("./../assets/bluebg.svg");
const LogoImg = require("./../assets/symplantext.svg");

const NavWrapper = glamorous.div({
  width: "69vw",
  display: "flex",
  flexWrap: "wrap",
  position: "fixed",
  zIndex: "1000",
  top: "7%",
  left: "0%",
});

const NavBar1 = glamorous.div({
  height: "40px",
  width: "68vw",
  backgroundImage: "url(" + BackgroundHeader + ")",
  display: "flex",
  justifyContent: "space-around",
  zIndex: "100",
});

const Logo = glamorous.div(
  {
    display: "flex",
    transition: Transition.Normal,
    position: "fixed",
    right: "0%",
    top: "2%",
  },
  props => ({
    opacity: props.isActive ? 1 : 0,
    ":hover": {
      cursor: props.isActive ? "pointer" : "",
    },
  }),
);
class NavBar extends Component {
  render() {
    //gives the height equal to 100vh
    console.log(window.innerHeight);
    const windowHeight = window.innerHeight;
    const { scrollHeight } = this.props;
    return (
      <div>
        <NavWrapper>
          <NavBar1>
            <NavBarItem text="about" />
            <NavBarItem text="projects" />
            <NavBarItem text="content" />
          </NavBar1>
          <div
            style={{
              backgroundColor: ColourPalette.TealLight,
              height: "30px",
              width: "1vw",
              alignSelf: "flex-end",
            }}
          />
          <div
            style={{
              backgroundColor: ColourPalette.TealLight,
              height: "13px",
              marginLeft: "1vw",
              width: "71vw",
              alignSelf: "flex-end",
            }}
          />
        </NavWrapper>
        <Logo isActive={scrollHeight > windowHeight}>
          <img
            src={LogoImg}
            alt=""
            style={{
              height: "20vh",
              width: "auto",
            }}
          />
        </Logo>
      </div>
    );
  }
}

export default NavBar;
