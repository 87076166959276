import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import Home1 from "./components/Home1";
import NavBar from "./components/NavBar";
import Home2 from "./components/Home2";

class App extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = { scrollHeight: 0 };
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("scroll", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateDimensions);
  }

  updateDimensions() {
    this.setState({ scrollHeight: window.scrollY });
    console.log(this.state.scrollHeight);
  }
  render() {
    return (
      <div className="App">
        <NavBar scrollHeight={this.state.scrollHeight} />
        <Home1 />
        <Home2 />
      </div>
    );
  }
}

export default App;
