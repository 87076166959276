import React, { Component } from "react";
import glamorous from "glamorous";
import { ColourPalette, FontColour, FontSize } from "../globals";
import NavBarItem from "./atoms/NavBarItem";

const SymplanText = require("./../assets/symplantext.svg");
const LogoTextSvg = require("./../assets/logotext.svg");

const BannerImage = require("./../assets/trapeziumcyclist.png");

const Wrapper = glamorous.div({
  backgroundColor: ColourPalette.Cream,
  //   height: "calc(100vh - 300px)",
  //   width: "calc(100vw - 200px)",
  height: "75vh",
  width: "75vw",
  padding: "25vh 0px 0px 25vw ",
  display: "flex",
});

const LogoText = glamorous.div({
  backgroundImage: "url(" + SymplanText + ")",
  height: "300px",
  width: "200px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top right",
  padding: "100px 250px 0px 0px",
  textAlign: "right",
  fontSize: FontSize.SubHeader,
});

const LogoText2 = glamorous.div({
  zIndex: "100",
  width: "20%",
});

const Image = glamorous.div({
  paddingTop: "2%",
  display: "flex",
  width: "80%",
});

class Home1 extends Component {
  render() {
    return (
      <Wrapper>
        {/*<LogoText>
          PUTTING
          <br />
          THE
          <br />
          SOCIAL
          <br />
          BACK INTO
          <br />
          PLANNING
        </LogoText>*/}
        <LogoText2>
          <img
            src={LogoTextSvg}
            alt=""
            style={{ width: "calc(80% + 200px)" }}
          />
        </LogoText2>
        <Image>
          <img
            src={BannerImage}
            alt=""
            style={{
              height: "auto",
              width: "70%",
              display: "flex",
              alignSelf: "start",
            }}
          />
        </Image>
      </Wrapper>
    );
  }
}

export default Home1;
